import { useEffect, useState } from "react";
import Dish from "../../../../model/dish.ts";
import DishCard from "../../../../components/dish-card/dish-card.tsx";
import { getAnonymousHttpClient } from "../../../../helpers/httpClientHelper.ts";
import {useNavigate, useParams} from "react-router-dom";
import Loading from "../../../../components/loading/loading.tsx";
import styles from "./category.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import CategoryDTO from "../../../../model/category.ts";
import {useSwipeable} from "react-swipeable";

export default function Category(){
    const navigate = useNavigate();
    const { categoryId } = useParams();
    const [dishes, setDishes] = useState<Array<Dish>>([]);
    const [dishIndex, setDishIndex] = useState(1);
    const [category, setCategory] = useState<CategoryDTO>();

    const handlers = useSwipeable({
        onSwipedLeft: nextCategory,
        onSwipedRight: previousCategory,
        onSwipedUp: nextDish,
        onSwipedDown: previousDish,
        preventScrollOnSwipe: true
    });

    useEffect(() => {
        const httpClient = getAnonymousHttpClient();

        if(!category){
            httpClient.get<CategoryDTO>(`/categories/${categoryId}`).then(response => {
                if(response.status == 200)
                {
                    setCategory(response.data);
                }
            });
        }

        if(dishes!.length < dishIndex){
            //Load Dish first dish
            httpClient.get<Dish>(`/dishes/${categoryId}/${dishIndex}`).then(response => {
                if(response.status == 200){
                    setDishes(old => [...old, response.data]);

                    httpClient.get<Dish>(`/dishes/${categoryId}/${dishIndex + 1}`).then(response => {
                        if(response.status == 200){
                            setDishes(old => [...old, response.data]);
                        }
                    });
                }
            });
        }

        if(dishes!.length == dishIndex && dishes[dishIndex - 1].hasNext){
            httpClient.get<Dish>(`/dishes/${categoryId}/${dishIndex + 1}`).then(response => {
                if(response.status == 200){
                    setDishes(old => [...old, response.data]);
                }
            });
        }
    }, [dishIndex, categoryId]);

    function nextCategory() {
        if(!category?.nextCategory){
            return;
        }

        setCategory(undefined);
        setDishes([]);
        setDishIndex(1);
        navigate(`/menu/${category?.nextCategory}`);
    }

    function previousCategory(){
        if(!category?.previousCategory){
            return;
        }

        setCategory(undefined);
        setDishes([]);
        setDishIndex(1);
        navigate(`/menu/${category?.previousCategory}`);
    }

    function nextDish(){
        if(!dishes[dishIndex - 1].hasNext){
            return;
        }

        setDishIndex(dishIndex + 1);
    }

    function previousDish(){
        if((dishIndex - 1) <= 0){
            return;
        }

        setDishIndex(dishIndex - 1);
    }

   return dishes[dishIndex - 1] && category ?
        <div className={`h-100`} {...handlers}>
            <div className={`${styles.categoryTitle} p-3 d-flex justify-content-around`}>
                <span onClick={() => previousCategory()}>{ category.previousCategory && <FontAwesomeIcon icon={ faChevronLeft } className={`${styles.menuIcon}`} />}</span>
                <span>{ category.name }</span>
                <span onClick={() => nextCategory()}>{ category.nextCategory && <FontAwesomeIcon icon={ faChevronRight } className={`${styles.menuIcon}`} />}</span>
            </div>
            <DishCard dish={dishes[dishIndex - 1]} onGoToHome={() => navigate("/menu")}></DishCard>
        </div>
       : <Loading></Loading>
}
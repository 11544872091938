import {useEffect, useMemo, useState} from "react";
import { getAnonymousHttpClient } from "../../helpers/httpClientHelper.ts";
import { useWindowSize } from "@uidotdev/usehooks";
import Category from "../../model/category.ts";
import MobileMenu from "./mobile-menu/mobile-menu.tsx";
import DesktopMenu from "./desktop-menu/desktop-menu.tsx";
import {useLayoutContext} from "../../state/layoutContext.tsx";

export default function Menu(){
    const { width } = useWindowSize();
    const { toggleLoader } = useLayoutContext();
    const [categories, setCategories] = useState<Category[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        const getData = async function(){
            toggleLoader(true);
            const httpClient = getAnonymousHttpClient();

            try {
                const response = await httpClient.get<Category[]>("/categories");
                if(response.status != 200){
                    setErrorMessage("Ha ocurrido un error, por favor recargue la pagina.");

                    return;
                }

                setCategories(response.data);
            }
            catch(e){
                setErrorMessage("Ha ocurrido un error, por favor recargue la pagina.");
            }
            toggleLoader(false);
        }

        getData();
    }, []);

    const isMobile = useMemo<boolean>(() => {
        return (width || 0) < 992;
    }, [width]);

    const isDesktop = useMemo<boolean>(() => {
        return (width || 0) >= 992;
    }, [width]);

    return (<>
        { errorMessage && <span>Ha ocurrido un error, por favor recargue la pagina.</span>}
        { !errorMessage && isMobile &&
            <MobileMenu categories={categories}></MobileMenu>
        }
        { !errorMessage && isDesktop &&
            <DesktopMenu categories={categories}></DesktopMenu>
        }
    </>);
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import {useContext, useEffect, useState} from "react";
import {VisibilityContext} from "react-horizontal-scrolling-menu";
import styles from './arrow.module.scss';

export default function RightArrow(){
    const {
        isLastItemVisible,
        scrollNext,
        visibleElements,
        initComplete
    } = useContext(VisibilityContext);

    const [disabled, setDisabled] = useState(
        !initComplete || (initComplete && isLastItemVisible)
    );

    useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleElements.length) {
            setDisabled(isLastItemVisible);
        }
    }, [isLastItemVisible, visibleElements]);

    return (
        <div className={`d-flex justify-content-center align-items-center ${styles.scroller}`} >
            { !disabled && (<FontAwesomeIcon className={"horizontal-scroll-button"} icon={ faCircleChevronRight } onClick={() => scrollNext()} />) }
        </div>
    );
}
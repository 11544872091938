import {IPublicClientApplication, SilentRequest} from "@azure/msal-browser";
import axios, {AxiosInstance} from "axios";

const getHttpClient = (msalInstance: IPublicClientApplication): AxiosInstance => {
    const client = axios.create({
        baseURL: import.meta.env.VITE_BACKEND
    });

    client.interceptors.request.use(async config => {
        const account = msalInstance.getAllAccounts()[0];
        const token = await msalInstance.acquireTokenSilent({scopes: [`${import.meta.env.VITE_BACKEND_RESOURCE}/categories.write`,`${import.meta.env.VITE_BACKEND_RESOURCE}/dishes.write`], account: account} as SilentRequest);

        config.headers["Authorization"] = `Bearer ${token.accessToken}`;

        return config;
    });

    return client;
}

const getAnonymousHttpClient = () => {
    return  axios.create({
        baseURL: import.meta.env.VITE_BACKEND
    });
}

export default getHttpClient;
export { getAnonymousHttpClient };
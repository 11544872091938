import React from 'react';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import LeftArrow from "./arrows/left-arrow.tsx";
import RightArrow from "./arrows/right-arrow.tsx";
import Card from './card.tsx';
import { ContextType, useState } from "react";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import Category from "../../../../../model/category.ts";

type scrollVisibilityApiType = ContextType<typeof VisibilityContext>;

export default function ScrollMenuComponent({ categories, onCategorySelect }: { categories: Category[]; onCategorySelect: (category: Category) => void }) {
  const [duration] = useState(500);

  function onWheel(
    apiObj: scrollVisibilityApiType,
    ev: React.WheelEvent
  ): void {
      const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
      if (isThouchpad) {
          ev.stopPropagation();
          return;
      }
      if (ev.deltaY < 0) {
          apiObj.scrollNext(undefined, undefined, undefined, { duration });
      } else if (ev.deltaY > 0) {
          apiObj.scrollPrev(undefined, undefined, undefined, { duration });
      }
  }
   
  return (
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        onWheel={onWheel}
        transitionDuration={duration}>
        {categories.map(category => (
          <Card key={category.id} categoryItem={category} onClick={() => onCategorySelect(category)} />
        ))}
      </ScrollMenu>
  );
}
import styles from "../menu.module.scss";
import CategoryButton from "../category-button/category-button.tsx";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import Layout from "../../../components/layout/layout.tsx";
import Category from "../../../model/category.ts";
import { useNavigate } from "react-router-dom";

export default function MobileMenu({categories}:{categories: Category[]}){
    const navigate = useNavigate();

    return <Layout>
        <div className="d-block d-lg-none">
            <div className={`${styles.wrapper} p-0 ${styles.wrapper}`}>
                <div className={`${styles.wrapperColor} ${styles.menuBody} pb-3`}>
                    <div>
                        <h1 className={`${styles.menuTitle} ms-3 pt-4 text-center`}>{ "MENU" }</h1>
                    </div>
                    {
                        categories.map((category, index) => {
                            return (<div className={``} key={category.id}>
                                <CategoryButton category={category} onClick={(c) => navigate(`/menu/${c.id}`)} {...{ leftIcon: (index % 2) ? faChevronLeft : undefined, rightIcon: !(index % 2) ? faChevronRight : undefined }}></CategoryButton>
                            </div>)
                        })
                    }
                </div>
            </div>
        </div>
    </Layout>
}
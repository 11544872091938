import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { BrowserRouter } from "react-router-dom";
import LayoutProvider from "./providers/layoutProvider.tsx";

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://84c9a167b2557fe2401479c0cdf31605@o4507563462033408.ingest.us.sentry.io/4507563466555392",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api.nau.customer.embrace\.mx\//],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <BrowserRouter>
        <LayoutProvider>
            <App />
        </LayoutProvider>
    </BrowserRouter>
)

import DishDetail from "../../../model/dishDetail.ts";
import styles from "./description.module.scss";

export default function Description ({detail}: {detail: DishDetail}) {
    return <>
        <div className={`${styles.descriptionContainer}`}>
            <span className={styles.iconWrapper}>
                {detail.icon && <img src={detail.icon} className={styles.dishIcon} />}
            </span>
            <span className={`d-inline-block ps-1 ${styles.dishDescription}`}>{detail.description}</span>
        </div>
    </>
}
import styles from './category-button.module.scss';
import Category from '../../../model/category.ts';
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createRef } from "react";

export default function CategoryButton({ category, leftIcon, rightIcon, onClick }: {category: Category, leftIcon?: IconDefinition|undefined, rightIcon?: IconDefinition|undefined, onClick: (category:Category, posY: number)=>void}){
    const titleRef = createRef<HTMLSpanElement>();

    return (
        <>
            <span onClick={() => onClick(category, titleRef.current?.offsetTop!)} ref={titleRef} className={`d-block text-center ${styles.categoryName} ${styles.header} p-3 mt-3 mb-1 d-flex justify-content-around`}>
                <span>{ leftIcon && <FontAwesomeIcon icon={ leftIcon } className={`${styles.menuIcon}`} />}</span>
                <span>{category.name}</span>
                <span>{ rightIcon && <FontAwesomeIcon icon={ rightIcon } className={`${styles.menuIcon}`} />}</span>
            </span>
        </>
    );
}
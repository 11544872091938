import logoImage from '../../../assets/img/logo.png'
import { Nav, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { useLayoutContext } from "../../../state/layoutContext.tsx";
import { Link } from "react-router-dom";

export default function Navbar({ }){
    const { isSideBarVisible:sideBarToggle, toggleSideBar:setSideBarToggle } = useLayoutContext();

    return (<>
        { <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <Link className="navbar-brand" to={"/"}>
                        <img src={logoImage} alt="Nau" />
                    </Link>
                    <Nav>
                        <NavLink href="#" onClick={() => setSideBarToggle(!sideBarToggle)}>
                            <FontAwesomeIcon icon={faBars} />
                        </NavLink>
                    </Nav>
                </div>
            </nav>
        }
    </>);
}
import styles from "../menu.module.scss";
import desktopStyles from "./desktop-menu.module.scss";
import Category from "../../../model/category.ts";
import Layout from "../../../components/layout/layout.tsx";
import ScrollMenuComponent from "./scroller/horizontal-scroll/scroll-menu.tsx";
import {useEffect, useState} from "react";
import Dish from "../../../model/dish.ts";
import { getAnonymousHttpClient } from "../../../helpers/httpClientHelper.ts";
import DishCard from "../../../components/dish-card/dish-card.tsx";
import Loading from "../../../components/loading/loading.tsx";

export default function DesktopMenu({categories}:{categories: Category[]}){
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const [dishes, setDishes] = useState<Array<Dish>>();

    const onCategorySelected = (category: Category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        const httpClient = getAnonymousHttpClient();

        setDishes([]);

        if(selectedCategory == null){
            return;
        }

        //Load Category Dishes
        httpClient.get<Dish[]>(`/dishes/all/${selectedCategory?.id}`).then(response => {
            if(response.status == 200){
                setDishes(response.data);
            }
        });
    }, [selectedCategory]);

    useEffect(() => {
        if(categories.length == 0){
            return;
        }

        setSelectedCategory(categories[0])
    }, [categories]);

    return <Layout>
        <div className={`${styles.menuBody}`}>
            <div className={``}>
                <div className={""}>
                    <h1 className={`${styles.menuTitle} ms-3 pt-4`}>MENU</h1>
                    <h2 className={`${styles.menuSubtitle} ms-2 mb-3`}>
                        Selecciona una categoria
                    </h2>
                </div>
            </div>
            <div className={""}>
                <div className={""}>
                    <ScrollMenuComponent categories={categories} onCategorySelect={onCategorySelected} />
                </div>
            </div>
            <div className={`container-fluid ${desktopStyles.container}`}>
                <div className={`row pt-2 pb-2`}>
                { selectedCategory && dishes &&
                    dishes.map(dish => {
                        return <div className={`col-4 d-inline-block ${desktopStyles.cardMaxHeight} gx-3 mb-3`} key={dish.id}>
                            <DishCard dish={dish} isDesktop={true} onGoToHome={() => {}}></DishCard>
                        </div>
                    })
                }
                {
                    selectedCategory && !dishes?.length && <Loading></Loading>
                }
                </div>
            </div>
        </div>
    </Layout>
}
import styles from "./dish-card.module.scss"
import Dish from "../../model/dish.ts";
import { Collapse } from "reactstrap";
import {useEffect, useState} from "react";
import Description from "./description/description.tsx";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loading from "../loading/loading.tsx";
import {getAnonymousHttpClient} from "../../helpers/httpClientHelper.ts";

export default function DishCard({ dish, onGoToHome, isDesktop }: { dish: Dish, onGoToHome: () => void, isDesktop?: boolean }){
    const [isExtraDataVisible, setExtraDataVisible] = useState(false);
    const [imgUrl, setImgUrl] = useState("");

    useEffect(() => {
        const httpClient = getAnonymousHttpClient();
        setExtraDataVisible(false);
        setImgUrl("");

        if(!dish.imgUrl){
            httpClient.get(`resources/${dish.id}`, {responseType: "blob"}).then(blobResponse => {
                if(blobResponse.status == 200){
                    const blobUrl = window.URL.createObjectURL(blobResponse.data);
                    setImgUrl(blobUrl);
                }
            });
        }
        else{
            setImgUrl(dish.imgUrl);
        }

        return () => {
            if(imgUrl){
                window.URL.revokeObjectURL(imgUrl);
            }
        }
    }, [dish]);

    return (<section
        className={styles.dishSection}
        style={{ ...(isDesktop ? {height: 'inherit'} : {}) }}>
        { !imgUrl && <div style={{position:"absolute", height:'100%', width:'100%', zIndex: 9999}}><Loading></Loading></div>}
        { !dish.isVideo ? <img className={`${styles.dishImage}`} src={`${imgUrl}`} style={{ ...(isDesktop ? {maxHeight: '100%', maxWidth:'100%'} : {maxHeight:'calc(100dvh - 63px)', maxWidth:'100%'}) }}></img> :<><video className={`${styles.dishVideo}`} style={{ ...(isDesktop ? {maxHeight: '100%'} : { objectFit: "cover"}) }} 
         loop={true} playsInline={true} autoPlay={true} muted={true} src={`${imgUrl}`}></video></> }
        <div className={`${styles.dishInfo} p-1 pe-2`}>
            {!isDesktop && <a onClick={onGoToHome} className={`${styles.homeButton}`}>
                <FontAwesomeIcon icon={faHouse} className={`${styles.homeButtonIcon}`}/>
            </a>
            }
            <h4 className={"ms-3"} style={{fontFamily: 'Incised901,sans-serif'}}>{dish.name}</h4>
            <h6 className={"ms-3"} style={{fontFamily: 'Monoela-Regular,sans-serif'}}>{dish.cost}</h6>
            <span className={styles.extraDataLabel}
                  onClick={() => setExtraDataVisible(!isExtraDataVisible)}>Ver {!isExtraDataVisible ? "mas" : "menos"}</span>
            <Collapse isOpen={isExtraDataVisible}>
                {
                    dish.details.map((d, index) => {
                        return <Description detail={d} key={index}></Description>
                    })
                }

            </Collapse>
        </div>
    </section>);
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { useContext, useEffect, useState } from "react";
import styles from './arrow.module.scss';

export default function LeftArrow(){
    const {
        isFirstItemVisible,
        scrollPrev,
        visibleElements,
        initComplete
    } = useContext(VisibilityContext);

    const [disabled, setDisabled] = useState(
        !initComplete || (initComplete && isFirstItemVisible)
    );

    useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleElements.length) {
            setDisabled(isFirstItemVisible);
        }
    }, [isFirstItemVisible, visibleElements]);

    return (
        <div className={`d-flex justify-content-center align-items-center ${styles.scroller}`} >
            { !disabled && (<FontAwesomeIcon className={"horizontal-scroll-button"} icon={ faCircleChevronLeft } onClick={() => scrollPrev()} />) }
        </div>
    );
}
import { useLayoutContext } from "../../../state/layoutContext.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import styles from './sidebar.module.scss';
import { Link } from "react-router-dom";

export default function Sidebar() {
    const { isSideBarVisible, toggleSideBar } = useLayoutContext();

    const handleCloseClick = () => {
        toggleSideBar(false);
    };

    return (
        <div className={`${styles.sidebar} ${!isSideBarVisible ? styles.hidden : ''}`}>
            <div onClick={handleCloseClick}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
            <ul onClick={() => toggleSideBar(false)}>
                <li>
                    <Link to={`/`} id={'dashboard-nav-link'} style={{fontFamily:'Incised901, sans-serif'}}>
                        INICIO
                    </Link>
                </li>
                <li>
                    <Link to={'/menu'} id={'menu-nav-link'} style={{fontFamily:'Incised901, sans-serif'}} >
                        MENU
                    </Link>
                </li>
            </ul>
        </div>
    );
}
import './styles/globals.scss'
import { RouteObject, useRoutes } from "react-router-dom";
import { lazy, Suspense } from "react";
import Menu from "./pages/menu/menu.tsx";
import {useLayoutContext} from "./state/layoutContext.tsx";
import Loading from "./components/loading/loading.tsx";
import Category from "./pages/menu/mobile-menu/category/category.tsx";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig.ts";
import { MsalProvider } from "@azure/msal-react";

const Admin = lazy(() => import("./pages/admin/admin.tsx"));
const Landing = lazy(() => import("./pages/landing/landing.tsx"));

export const msalInstance = new PublicClientApplication(msalConfig);

function App() {
    const routes: RouteObject[] = [
        {
            path: "/",
            element: <Suspense fallback={<Loading />}>
                <Landing />
            </Suspense>,
        },
        {
            path: "/menu",
            element: <Menu></Menu>
        },
        {
            path: "/menu/:categoryId",
            element: <Category></Category>
        },
        {
            path: "/admin",
            element: <MsalProvider instance={msalInstance}><Admin /></MsalProvider>
        }
    ];
    const child = useRoutes(routes);
    const { isLoading } = useLayoutContext();

    return (
        <>
            { child }
            { isLoading && <Loading></Loading> }
        </>
    )
}

export default App

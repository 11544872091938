import styles from "./card.module.scss";
import Category from "../../../../../model/category.ts";

export default function Card({ categoryItem, onClick}: { categoryItem: Category, onClick:(item: Category) => void}){
    return (
        <article className={`d-inline-block ${styles.cardBody} p-3`} onClick={() => onClick(categoryItem)}>
            <span className={styles.cardText}>{categoryItem.name}</span>
            { categoryItem.icon && <><br /><img className={"pt-1"} src={categoryItem.icon} height={50}></img></> }
            <br/>
        </article>
    )
}
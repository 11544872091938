import { useCallback, useState } from "react";
import LayoutContext from "../state/layoutContext";
import { ProviderChildrenType } from "../model/providerChildrenType";
import { Layout } from "../model/layout";

const LayoutProvider = ({children}: ProviderChildrenType)  => {
    const toggleSideBar = useCallback((val: boolean) => { setLayoutState(old => { return {...old, isSideBarVisible: val, toggleSideBar}}); }, []);
    const toggleLoader = useCallback((val: boolean) => { setLayoutState(old => { return {...old, isLoading: val, toggleLoader}})}, []);
    const [layoutState, setLayoutState] = useState<Layout>({isSideBarVisible: false, isLoading: false, toggleSideBar, toggleLoader});

    return (<LayoutContext.Provider value={ layoutState }>
        {children}
    </LayoutContext.Provider>);
};

export default LayoutProvider;

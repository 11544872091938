import React, { useContext } from "react";
import { Layout } from "../model/layout";

const LayoutContext = React.createContext<Layout>({
    isSideBarVisible: false,
    isLoading: false,
    toggleSideBar: () => undefined,
    toggleLoader: () => undefined
});

const useLayoutContext = () => {
  return useContext<Layout>(LayoutContext);
};

export default LayoutContext;

export { useLayoutContext };

import styles from './footer.module.scss';
import Logo from '../../../assets/img/footerlogo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faUber } from "@fortawesome/free-brands-svg-icons/faUber";

export default function Footer({ }){
    const schedule = [
        { day: 'Lunes a Jueves', hours: '1:00 p.m. - 10:00 p.m.' },
        { day: 'Viernes y Sabado', hours: '1:00 p.m. - 12:00 a.m.' },
        { day: 'Domingos', hours: '1:00 p.m. - 10:00 p.m.' },
    ];

    return (<>
            { <footer className={styles.footer} >
                    <div className={styles.container}>
                        <img src={Logo} alt="Nau"  className={styles.logoImg}/>
                        <div className={styles.socialMedia}>
                            <a href="https://www.facebook.com/metepec.nau">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a href="https://www.instagram.com/nau.metepec/?hl=es">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a href="https://www.ubereats.com/mx/store/nau-sushi/wAn7ooXES1eZgarG33SiTA">
                                <FontAwesomeIcon icon={faUber} />
                            </a>
                        </div>
                    </div>
                    <ul className={styles.schedule}>
                        {schedule.map((item) => (
                            <li key={item.day}>{item.day}: {item.hours}</li>
                        ))}
                    </ul>
                </footer>
            }
    </>);
}
import Navbar from "./navbar/navbar.tsx";
import Footer from "./footer/footer.tsx";
import Sidebar from "./sidebar/sidebar.tsx";
import { ProviderChildrenType } from "../../model/providerChildrenType.ts";

export default function Layout({ children }: ProviderChildrenType){
    return (
        <>
            <Navbar></Navbar>
            <Sidebar />
            <div>
                { children }
            </div>
            <Footer></Footer>
        </>
    );
}